import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link } from "react-router-dom";
import { InputContext } from '../contexts/InputContext';

const MultimediaGaleria = () => {
  const closeRef = useRef(null);
  const [renderHTML, setRenderHTML] = useState(false);
  const [authInformation, setAuthInformation, checkSignIn] = useContext(InputContext)

  useEffect(() => {
    checkSignIn()
  }, []);

  useEffect(() => {
    if(authInformation.autenticado === true && authInformation.rol === 'Admin'){
      setRenderHTML(true);
    }
  }, [authInformation]);


  const cerrarModal = () => {
    closeRef.current.click();
  }

  return (
    <div className='image-bg'>
      {renderHTML ? (
        <>
      <div className='content'>

        <div className='main-message d-flex align-items-center text-center justify-content-center' style={{height:'100vh'}}>
            {/* <button onClick={() => comenzar()} className='btn btn-primary' type='button'>Comenzar</button> */}
            {/* <button onClick={() => setEsTest(true)} className='btn btn-primary ms-3' type='button'>Comenzar Test</button> */}
            <button data-bs-toggle="modal" data-bs-target="#videoSourceModal" className='btn btn-primary ms-3' type='button'>Opciones de animaciones</button>
        </div>

        </div>
        <div className="modal fade" id="videoSourceModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <h3>Opciones de animaciones</h3>
                <div className='border-bottom mb-2 pb-2'>
                  <Link to={`/multimedia-publico-imagen`} target="_blank" onClick={() => cerrarModal()} className="btn btn-success me-1 mb-1" >Actual</Link>
                  <Link to={`/multimedia-publico/avatar3d`} target="_blank" onClick={() => cerrarModal()} className="btn btn-primary me-1 mb-1" >3D Avatar</Link>
                </div>
                <div>
                  <Link to={`/multimedia-publico/public-images`} target="_blank" onClick={() => cerrarModal()} className="btn btn-success me-1 mb-1" >Logo</Link>
                  <Link to={`/multimedia-publico/public-images/imagenes`} target="_blank" onClick={() => cerrarModal()} className="btn btn-success me-1 mb-1" >Logo + Imágenes</Link>
                  <Link to={`/multimedia-publico/public-images/votacion`} target="_blank" onClick={() => cerrarModal()} className="btn btn-success me-1 mb-1" >Logo + Votación</Link>
                  <Link to={`/multimedia-publico/public-mixing/imagenes`} target="_blank" onClick={() => cerrarModal()} className="btn btn-primary me-1 mb-1" >Avatar + Imágenes</Link>
                  <Link to={`/multimedia-publico/public-mixing/votacion`} target="_blank" onClick={() => cerrarModal()} className="btn btn-primary me-1 mb-1" >Avatar + Votación</Link>
                </div>
                <div className='mt-3'>
                  <h4>Tests</h4>
                  <Link to={`/multimedia-publico/public-images/imagenes-test`} target="_blank" onClick={() => cerrarModal()} className="btn btn-success me-1 mb-1" >Logo + Imágenes</Link>
                  <Link to={`/multimedia-publico/public-images/votacion-test`} target="_blank" onClick={() => cerrarModal()} className="btn btn-success me-1 mb-1" >Logo + Votación</Link>
                  {/* <Link to={`/multimedia-publico/public-images/imagenes-votacion-test`} target="_blank" onClick={() => cerrarModal()} className="btn btn-success me-1 mb-1" >Logo + Imágenes + Votacion</Link> */}
                  <Link to={`/multimedia-publico/public-mixing/imagenes-test`} target="_blank" onClick={() => cerrarModal()} className="btn btn-primary me-1 mb-1" >Avatar + Imágenes</Link>
                  <Link to={`/multimedia-publico/public-mixing/votacion-test`} target="_blank" onClick={() => cerrarModal()} className="btn btn-primary me-1 mb-1" >Avatar + Votación</Link>
                </div>

              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" ref={closeRef} data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
        </>
      ) : (
        <h3>Sin Permiso</h3>
      )}
    </div>
  );
};

export default MultimediaGaleria;